<template>
  <div v-if="services.length" class="container containerLiveTracker">
    <div class="row">
      <div class="col-12 text-center mb-5">
        <h1 class="font-weight-bold">Live Service Tracker</h1>
        <h5>A real time update on services and current availabilities</h5>
      </div>
    </div>
    <div class="row w-100  mb-3">
      <div class="col-lg-4 col-12 pr-lg-5 mb-lg-0 mb-3">
        <input type="text" v-model="searchValue" placeholder="Search Service" id="search-input">
      </div>
      <div class="col-lg-8 col-12 d-flex flex-row justify-content-between align-items-center ">
        <p class="mb-0 d-flex flex-row justify-content-center align-items-center" @click="sort('title')" :class="[sortBy === 'title' ? sortDirection : '']">
          Alphabetical <span class="material-icons">expand_more</span></p>
        <p class="mb-0 d-flex flex-row justify-content-center align-items-center" @click="sort('updated')" :class="[sortBy === 'updated' ? sortDirection : '']">
          Last Updated <span class="material-icons">expand_more</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row feed-headline w-100 py-3">
          <div class="col-12">
            <div class="row">
              <div class="col-3">Service Name</div>
              <div class="col-1 d-lg-block d-none">Suburb</div>

              <div class="text-center col-lg-7 col-sm-8 col-9 ">
                <div class="row">
                  <div class="col-md-2 col-4">Operating<br>as Normal</div>
                  <div class="col-md-2 col-4">Reduced<br>Staffing</div>
                  <div class="col-md-2 col-4">Reduced<br>Intake</div>
                  <div class="col-2 d-md-block d-none">Online Phone Contact</div>
                  <div class="col-4 d-md-block d-none">
                    <div class="row">
                      <div class="col-12 text-center">
                        Face-to-Face
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col-6">On premisise</div>
                      <div class="col-6">Outreach</div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-1 d-sm-block d-none">Last Updated</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row w-100" v-for="service in filteredRecipes" :key="service.id">
          <div class="col-12">
            <LiveFeedTracker :service="service" />
          </div>
        </div>
      </div>



    </div>
  </div>
  <div v-else>
    <Spinner />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import LiveFeedTracker from '@/components/LiveFeedTracker.vue'

export default {
name: "LiveFeed",
 components: {
    LiveFeedTracker, Spinner
  },
    props: [],
 data() {
    return {
    services:[],
      searchValue: '',
        sortBy: 'name',
        sortDirection: 'asc'
    }
 },
 computed:{
 filteredRecipes() {
    let tempServices = this.services.filter(service => {
          return service.youthDoctor == false
        })
    // Process search input
    if (this.searchValue != '' && this.searchValue) {
        tempServices = tempServices.filter((item) => {
          return item.title
            .toUpperCase()
            .includes(this.searchValue.toUpperCase())
        })
    }
    this.services.sort((p1,p2) => {
              
                    let modifier = 1;
                    if(this.sortDirection === 'desc') modifier = -1;
                    if(p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier; if(p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
                    return 0;
                });

    return tempServices
      },

     
 },

 methods: {
          sort: function(s){
                if(s === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = s;
            }
 },

   async mounted () {
    const axios = require('axios');
     this.loading = true,
      axios.get('https://wayouthservicesdirectory.org.au/service/?token=005182f96b3e68d0acc30fbccfc633d5')
      .then((response) => {
        this.services = response.data;
        // console.log(response.data);
      })
      .catch((error) => {
        this.error = true;
        console.log(error)
      }).finally(() => {
        this.loading =  false;
        // console.log(this.loading)
        // console.log(this.servicesJson)
      });
     },
  created() {
     },
}
</script>

<style>
.feed-headline{
  font-size:12px;
      line-height: 1.3;
}

.containerLiveTracker{
  background: #fff;
}
</style>
