import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import * as VueGoogleMaps from 'vue2-google-maps'

import AllServices from '../views/AllServices.vue'
import Category from '../views/Category.vue'
import YouthDoctor from '../views/YouthDoctor.vue'

import LiveFeed from '../views/LiveFeed.vue'
import FourOFour from '../views/FourOFour.vue'


import NotFound from '@/components/NotFound.vue'



Vue.use(VueRouter)

Vue.use(VueGoogleMaps, {
  load: {
  key: 'AIzaSyBw-Ld2uyp74D05cs6QOK3CVpnKcjxJ1e0',
  libraries: 'places,geometry'
  }
});

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/all-services',
    name: 'AllServices',
    component: AllServices
  },
  {
    path: '/live-feed',
    name: 'LiveFeed',
    component: LiveFeed
  },

  {
    path: '/services/:org',
    name: 'Category',
    component: Category
  },

  // {
  //   path: '/services-cat/:org',
  //   name: 'Category',
  //   component: Category
  // },

  {
    path: '/youth-doctors',
    name: 'YouthDoctor',
    component: YouthDoctor
  },
  
  // {
  //   path: 'service/:catchAll(.*)',
  //   name: 'Not Found',
  //   component: NotFound
  // }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

// // router guards
// router.beforeEach((to, from, next) => {
//   // check to see if route has auth guard
//   if(to.matched.some(rec => rec.meta.requiresAuth)){
//     // check auth state of user
//     let user = auth.currentUser
//     if (user) {
//       // User is signed in. Proceed to route
//       next()
//     } else {
//       // No user is signed in. Redirect to login
//       next({
//         name: 'Login'
//       })
//     }
//   } else {
//     // if route is not guarded by auth, proceed
//     next()
//   }
// })

export default router
