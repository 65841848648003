import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Search from './Search.vue'
import './assets/main.css'

Vue.config.productionTip = false

var one = new Vue({
  router,
  el: "#app",
  render: h => h(App)
})

var two = new Vue({
  router,
  el: "#vue-app-two",
  render: h => h(Search)
})


