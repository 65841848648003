<template>
<div class="container">
  <h1>Youth Doctor</h1>
  <div class="row">
   
     

                   <div class="col-lg-6 col-12 gutters d-lg-block d-none">
                <div class="fixed-google-map mb-lg-5">
                  <GmapMap  id="map" ref="mapRef" :center="center" :zoom="zoom" style='width:100%;  height: 400px;' :options="mapStyle">
                    <gmap-info-window :options="infoOptions " :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                      <div class="info-window">
                        <p class="mb-0"><b>{{infoContent.name}}</b></p>
                        <p class="mb-0">Phone: {{infoContent.phone}}</p>
                        <span v-if="infoContent.address"><a :href="`https://maps.google.com/maps?daddr=${infoContent.address}`">Get Directions</a></span> <span v-else>Contact Service for Address</span>
                      </div>
                    </gmap-info-window>

                    <GmapMarker :key="index" v-for="(m, index) in services" :position="{lat:Number(m.newlat),lng: Number(m.newlng )}" :clickable="true"
                      @click="toggleInfoWindow(m,index)" :id="m.id" ref="marker"  />
                  </GmapMap>
                  <!-- <div class="d-flex flex-row align-items-center justify-content-start" ref="okBtn" @click="locatorButtonPressed"> <span><small
                        class="font-weight-bold">Locate Me</small></span>
                    <span class="material-icons">
                      travel_explore
                    </span></div> -->
                    <div class="row w-100 d-flex justify-content-between align-items-center">
 <gmap-autocomplete
                @place_changed="setPlace"
              >
              </gmap-autocomplete>
                          <select v-model="radius">
              <option value="2">2</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="500">500</option>
            </select>
              <button id="searchBtn" type="button" class="btn btn-primary" @click="updateLocations">Search</button>
                 <button type="button" class="btn btn-primary" @click="reset">Reset</button>
                
              </div>
                  <!-- <div class="search d-flex flex-row w-100 mt-3">
                    <div class="col-4">
                      <p class="mb-0"><small class="font-weight-bold">Show Services Near Me</small></p>
                    </div>
                    <div class="col-8">
                      <div class="range-slider-holder">
                        <input type="range" min="0" max="20" id="distance" class="range-slider" v-model="radius" step="5" @change="calculateReturn" />
                        <output for="distance" :value="radius" class="range-slider-tooltip" :style="{ left: positionCalc + 'px' }">
                          <span class="mr-2">&laquo;</span>
                          <span v-if="radius <=4 ">Under 5KM</span>
                          <span v-else-if="radius >=20 ">Over {{ radius }}KM </span>
                          <span v-else>WITHIN {{ radius }}KM</span>
                          <span class="ml-2">&raquo;</span>
                        </output>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="col-lg-6">
                        <div class="ydService" v-for="service in services" :key="service.title" :data-lat="service.newlat" :data-lng="service.newlng" @mouseover="getData = true" @mouseleave="getData = false">
        <h5 class="w-100">{{service.title}}</h5>  
        <div class="row d-flex justify-content-between align-itms-center flex-row">
         <a :href="`https://wayouthservicesdirectory.org.au/youth-doctor/` + service.slug "><span class="mr-2"><small>Phone: {{service.telephone}}</small></span>
            <span class="mr-2"><small>Address: {{service.address}}</small></span>
        <span v-show="service.distance"><small>Distance: {{Math.ceil(Number(service.distance) * 0.001)}} kms</small></span><span class="d-none">{{newDistance}}</span>
        </a> 
        </div>
        
      </div>
              </div>

    
  </div>
</div>

</template>

<script>
import services from '@/stores/DoctorStore'
import * as VueGoogleMaps from 'vue2-google-maps'
export default {
  name: 'YouthDoctor',
  computed: {
    google: VueGoogleMaps.gmapApi
  },
  data(){
    return {
        services: [],
        doctorJson: [],
        success: false,
        error: false,
        loading: false,
        center: {
        lat: -31.949003199248203,
        lng: 115.86049272274552
      },
      currentPlace: null,
      place: null,
      radius: 5,
      lat: 0,
      lng: 0,
      range: 5,
      zoom: 12,
      distance: 0,
      infoContent: {},
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      positionCalc:'',
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      placeholder: '',
      mapStyle: {
         draggable:true,
           zoomControl: false,
           mapTypeControl: false,
           scaleControl: false,
           streetViewControl: false,
           rotateControl: false,
           fullscreenControl: false,
           animation: 2,
           disableDefaultUI: true,
           styles: [{featureType: "water",elementType: "geometry",stylers: [{color: "#e9e9e9"},{lightness: 17 } ] }, { featureType: "landscape", elementType: "geometry", stylers: [ { color: "#f5f5f5" }, { lightness: 20 } ] }, { featureType: "road.highway", elementType: "geometry.fill", stylers: [ { color: "#ffffff" }, { lightness: 17 } ] }, { featureType: "road.highway", elementType: "geometry.stroke", stylers: [ { color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 } ] }, { featureType: "road.arterial", elementType: "geometry", stylers: [ { color: "#ffffff" }, { lightness: 18 } ] }, { featureType: "road.local", elementType: "geometry", stylers: [ { color: "#ffffff" }, { lightness: 16 } ] }, { featureType: "poi", elementType: "geometry", stylers: [ { color: "#f5f5f5" }, { lightness: 21 } ] }, { featureType: "poi.park", elementType: "geometry", stylers: [ { color: "#dedede" }, { lightness: 21 } ] }, { elementType: "labels.text.stroke", stylers: [ { visibility: "on" }, { color: "#ffffff" }, { lightness: 16 } ] }, { elementType: "labels.text.fill", stylers: [ { saturation: 36 }, { color: "#333333" }, { lightness: 40 } ] }, { elementType: "labels.icon", stylers: [ { visibility: "off" } ] }, { featureType: "transit", elementType: "geometry", stylers: [ { color: "#f2f2f2" }, { lightness: 19 } ] }, { featureType: "administrative", elementType: "geometry.fill", stylers: [ { color: "#fefefe" }, { lightness: 20 } ] }, { featureType: "administrative", elementType: "geometry.stroke", stylers: [ { color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 } ] } ]        
      },
      markerIcon: 'https://wayouthservicesdirectory.org.au/wp-content/uploads/2021/07/ways-map-marker1.png',
      catTitle: '',
      value: 'Select a Fruit',
      list: ["1-3km", "1-5km","1-10km", "10-20km", "20km+"],
      visible: false,
      isEditing: false,
      newDistance: ''
      }
    },

computed: {

},

methods:{
    toggleInfoWindow(service, idx) {
          this.infoWindowPos = {
              lat: Number(service.newlat),
              lng: Number(service.newlng)
            },
            this.infoContent = {
              name: service.title,
              phone: service.telephone,
              address: service.address
            }

          if (this.currentMidx === idx) {
            this.infoWinOpen = !this.infoWinOpen
          } else {
          this.infoWinOpen = true
          this.currentMidx = idx
          }
        },

    setPlace (place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
      this.place = place
      this.computeDistance()
      console.log( place);
    },

    updateLocations () {
      this.convertRadius()
      this.filterMarkers()
      this.sortMarkers()
      // set map zoom higher for zoom in effect
      this.zoom = 14
    },

     reset () {
       //console.log(this.services)

     },

geolocate () {
          navigator.geolocation.getCurrentPosition( position => {
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          } );
        },

checkParam () {
  
    let q = this.$route.query
    if (Object.keys(q).length !== 0 ) {  
      console.log('not-empty');
      this.center = {
              lat: Number(this.$route.query.lat),
              lng: Number(this.$route.query.lng),
            };
      this.radius = Number(this.$route.query.r) * 1000
     // console.log( this.radius); // true
    
    }
  },

   getData: function(event){
          console.log(event.target.dataset.lat)
          console.log(event.target.dataset.lng)
    //       this.$refs.mapRef.$mapPromise.then((map) => {
    // this.zoom = 12;
    // map.panTo(parseFloat(event.target.dataset.lat),parseFloat( event.target.dataset.lng));
   
    // })
        },

        computeDistance () {
        this.services.forEach(service => {
        let center = new google.maps.LatLng(this.center)
        let targetLoc = new google.maps.LatLng( {lat:Number(service.newlat),lng: Number(service.newlng )} )
        let distance = google.maps.geometry.spherical.computeDistanceBetween(center, targetLoc)
        service['distance'] = distance

        this.newDistance = distance
        })
      },

 convertRadius () {
      this.radius = Number(this.radius) * 1000
      console.log(this.radius);
    },

    filterMarkers () {
      this.services = this.services.filter(service => service.distance < this.radius)
       //console.log(this.services);
    },

    sortMarkers () {
      this.services.sort((a, b) => a.distance - b.distance)
    },
 
    },
    
     
 mounted() {
   this.$gmapApiPromiseLazy();
     let self = this;
self.$refs.mapRef.$mapPromise.then(() => {
  console.log('googlemaploaded')
  this.computeDistance()
})
 
},
    created () {   
      const axios = require('axios');
        axios.get('https://wayouthservicesdirectory.org.au/youth-doctor/?token=005182f96b3e68d0acc30fbccfc633d5')
      .then((response) => {
        this.services = response.data;
        console.log(this.services);
      })
        .then((response) => {
        this.zoom = 20;
      let q = this.$route.query
    if (Object.keys(q).length !== 0 ) {  
      console.log('not-empty');
      this.center = {
              lat: Number(this.$route.query.lat),
              lng: Number(this.$route.query.lng),
            };
      this.radius = Number(this.$route.query.r) * 1000
    }
     this.services.forEach(service => {
         let position =  {
            lat: Number(service.newlat),
            lng: Number(service.newlng)
          };
        service['position'] = position
    });
      })
      .catch((error) => {
        this.error = true;
        console.log(error)
      })
    },
}
</script>

<style>

</style>
